import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import {
  Grid,
  makeStyles,
  useTheme,
  Button,
  Typography,
  withStyles,
  Tooltip,
  Dialog,
  DialogContent,
  Box,
} from '@material-ui/core';
import SvgCircleCheck from '../Icons/gen/CircleCheck';
import SvgCircleCross from '../Icons/gen/CircleCross';
import InfoIcon from '../Icons/gen/CircleInfo';
import Skeleton from '@material-ui/lab/Skeleton';
import LoadingAIIndicator from '../LoadingAIIndicator';

const GuidTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: '#353535',
    boxShadow: theme.shadows[1],
    fontSize: 14,
    padding: '33px 22px',
    lineHeight: '195.4%',
  },
}))(Tooltip);

const scoreToFixed = (score, fractionDigits = 1) =>
  (Math.round(score * 10) / 10).toFixed(fractionDigits);

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    // height: '47px',
    background: theme.palette.background.default,
    color: '#000',
    padding: '13px 81px',
    [theme.breakpoints.down('md')]: {
      padding: '13px 12px',
    }
  },
  mainHeader: {
    borderBottom: '34px solid #0F4C8F',
    gap: '12px'
  },
  headerMobile: {
    display: 'flex',
    height: '47px',
    background: theme.palette.primary.main,
    color: '#fff',
    padding: '15px',
  },
  headerText: {
    fontSize: '18px',
    fontWeight: 600,
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    }
  },
  headerTextMobile: {
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'center',
  },
  tableHeader: {
    backgroundColor: theme.palette.background.default,
    color: '#fff',
  },
  body: {
    background: '#fff',
    padding: '48px 77px',
    [theme.breakpoints.down('md')]: {
      padding: '24px 12px',
    }
  },
  mobileHeader: {
    height: '47px',
    background: theme.palette.primary.main,
    color: '#fff',
    padding: '15px 10px',
  },
  mobileBody: {
    background: '#fff',
    padding: '0 10px',
  },
  scoreCol: {
    width: '90px',
    textAlign: 'center',
  },
  table: {
    width: '100%',
    borderSpacing: 0,
    background: '#fff',
    '& thead': {
      textAlign: 'left',
      '& th': {
        fontSize: '14px',
        textTransform: 'uppercase',
        fontWeight: 600,
        color: '#0F4C8F',
        padding: '8px 16px',
        [theme.breakpoints.down('md')]: {
          fontSize: '12px'
        }
      },
    },
    '& tbody': {
      '& td': {
        border: '0px solid rgba(102, 102, 102, 0.08)',
        padding: '12px 23px',
        fontSize: '13px',
        fontWeight: 500,
        borderBottom: '1px solid rgba(224, 224, 224, 1)'
      },
    },
  },
  firstTr: {
    fontSize: '14px',
    fontWeight: 600,
    backgroundColor: theme.palette.background.default,
    color: '#fff',
    fontSize: '14px',
    textTransform: 'uppercase',
    fontWeight: 600,
    color: '#808080',
    padding: '8px 16px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    },
    '& td': {
      border: '0px solid rgba(102, 102, 102, 0.08)',
      padding: '12px 23px',
      fontSize: '14px !important',
      fontWeight: '600 !important',
      borderBottom: '1px solid rgba(224, 224, 224, 1)'
    },
  },
  skillDescription: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '21px',
    padding: '4px 23px',
  },
  scoreSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#EDF0F2',
    // padding: '12px 10px',
  },
  upgradeButton: {
    width: '126px',
    height: '33.8px',
    border: 'none',
    borderRadius: '50px',
    background: theme.palette.primary.main,
    color: '#fff',
    textTransform: 'none',
    marginRight: '21px',
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  close: {
    width: '60px',
    height: '33.8px',
    border: 'none',
    borderRadius: '50px',
    background: '#DADADA',
    color: '#303030',
    textTransform: 'none',
  },
  itemContainer: {
    display: 'inline-flex',
    marginBottom: 10,
    '& p': {
      margin: '0 4px',
    },
  },
  statusItems: {
    display: 'flex',
    '& div': {
      margin: '0 5px',
      '& span': {
        width: '9px',
        height: '9px',
        borderRadius: '50%',
      },
    },
  },
  icon: {
    padding: '0 8px',
    '&:first-child': {
      paddingLeft: 0,
    },
  },
  tabButton: {
    width: '100%',
    height: '53px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#DADADA',
    color: '#808080',
    fontSize: '14px',
    fontWeight: 700,
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
  },
  activeTab: {
    background: '#EDF0F2',
    color: theme.palette.primary.main,
  },
  closeButton: {
    width: '100%',
    height: '41px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#DADADA',
    color: '#303030',
    fontSize: '14.4px',
    fontWeight: 500,
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    marginBottom: '50px',
  },
  upgradeButtonMobile: {
    width: '100%',
    height: '41px',
    border: 'none',
    borderRadius: '3px',
    background: theme.palette.primary.main,
    color: '#fff',
    textTransform: 'none',
    marginBottom: '19px',
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
}));

const AiScore = ({
  category,
  score,
  skillMaxScore,
  answer,
  useragent,
  onClose,
  type,
  acceptbleAnswer,
  questionOption,
  loading
}) => {
  const router = useRouter();
  const classes = useStyles();
  const theme = useTheme();
  const [traitsTable, setTraitsTable] = useState([]);
  const [skillTable, setSkillTable] = useState([]);
  const [maxScore, setMaxScore] = useState(0);
  const [currectAnswerCount, setCurrectAnswerCount] = useState(0);
  const [skillDescription, setSkillDescription] = useState('');
  const [taskScore, setTaskScore] = useState(0);
  const [availablePair, setAvailablePar] = useState([]);
  const [yourPair, setYourPair] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [headerTitle, setHeaderTitle] = useState('');
  const [guid, setGuid] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const checkOptionStatus = (item) => {
    if (acceptbleAnswer.find((el) => el.toLowerCase() === item.toLowerCase()))
      return true;
    return false;
  };

  const scoreToFixed = (score, fractionDigits = 1) =>
    (Math.round(score * 10) / 10).toFixed(fractionDigits);

  const checkFillInTheBlank = (item, index) => {
    let status = false;
    acceptbleAnswer.forEach((el, ind) => {
      if (item && item.toLowerCase() === el.toLowerCase() && index === ind) {
        status = true;
      }
    });
    return status;
  };

  const checkHighlightIncorrectWords = (word, index) => {
    return !!(
      acceptbleAnswer.findIndex((i) => i.value === word && i.key === index) !==
      -1
    );
  };

  useEffect(() => {
    if (answer && acceptbleAnswer) {
      switch (category) {
        case 'ReadingMultipleChoiceSingleAnswer':
        case 'ListeningMultipleChoiceSingleAnswer':
        case 'SelectMissingWord':
        case 'HighlightCorrectSummary':
          if (answer === acceptbleAnswer) {
            setCurrectAnswerCount(1);
          } else {
            setCurrectAnswerCount(0);
          }
          break;
        case 'ReadingMultipleChoiceMultipleAnswer':
        case 'ListeningMultipleChoiceMultipleAnswer':
          answer.forEach((item) => {
            if (acceptbleAnswer.indexOf(item) >= 0) {
              setCurrectAnswerCount((prev) => prev + 1);
            }
          });
          break;
        case 'ReadingFillInTheBlanks':
          answer.forEach((item, index) => {
            acceptbleAnswer.forEach((el, ind) => {
              if (item === el && index === ind) {
                setCurrectAnswerCount((prev) => prev + 1);
              }
            });
          });
          break;
        case 'ReadingAndWritingFillInTheBlanks':
        case 'ListeningFillInTheBlanks':
          answer.forEach((item, index) => {
            if (checkFillInTheBlank(item, index)) {
              setCurrectAnswerCount((prev) => prev + 1);
            }
          });
          break;
        case 'HighlightIncorrectWords':
          answer.forEach((item) => {
            if (
              acceptbleAnswer.findIndex(
                (i) => i.value === item.value && i.key === item.key,
              ) !== -1
            ) {
              setCurrectAnswerCount((prev) => prev + 1);
            }
          });
          break;
        case 'ReorderParagraph':
          for (let i = 0; i < acceptbleAnswer.length - 1; i++) {
            const pair = `[${acceptbleAnswer[i]}, ${acceptbleAnswer[i + 1]}]`;
            setAvailablePar((prev) => [...prev, pair]);
          }
          for (let i = 0; i < answer.length - 1; i++) {
            const pair = `[${answer[i]}, ${answer[i + 1]}]`;
            setYourPair((prev) => [...prev, pair]);
          }
          break;
        default:
          setCurrectAnswerCount(0);
      }
    }
  }, [answer, acceptbleAnswer]);

  useEffect(() => {
    if (
      yourPair &&
      yourPair.length > 0 &&
      availablePair &&
      availablePair.length > 0
    ) {
      yourPair.forEach((item, index) => {
        const itemPair = availablePair.find((el, i) => item === el);
        if (itemPair) {
          setTaskScore((prev) => Number(prev) + 1);
        }
      });
    }
  }, [availablePair, yourPair]);

  useEffect(() => {
    switch (category) {
      case 'ReadingMultipleChoiceSingleAnswer':
        setTraitsTable([
          {
            key: 'Number of Options',
            value: questionOption.length,
          },
          {
            key: 'Correct Answers Available',
            value: 1,
          },
          {
            key: 'Your Correct Answer',
            value: currectAnswerCount,
          },
          {
            key: 'Your Wrong Answer',
            value: currectAnswerCount - 1 < 0 ? 1 : currectAnswerCount - 1,
          },
        ]);
        setTaskScore(currectAnswerCount);
        setSkillTable([
          {
            key: type,
            value: `${scoreToFixed(score[type])}/${scoreToFixed(
              skillMaxScore[type],
            )}`,
          },
        ]);
        setSkillDescription(
          `In real exam, each MC (CSA) question may contribute up to ${scoreToFixed(skillMaxScore[type])} marks toward Reading. (Asm: 2Q total)`,
        );
        if (useragent.isDesktop)
          setHeaderTitle('Multiple Choice (Choose Single Answer)');
        else setHeaderTitle('(R) MC (Choose Single Answer)');
        setMaxScore(15);
        setGuid(
          '<strong>1</strong> Each correctly chosen option<br/><strong>0</strong> Minimum score <strong>(no negative marking)</strong>',
        );
        break;
      case 'ReadingMultipleChoiceMultipleAnswer':
        setTraitsTable([
          {
            key: 'Number of Options',
            value: questionOption.length,
          },
          {
            key: 'Correct Answers Available',
            value: acceptbleAnswer.length,
          },
          {
            key: 'Your Correct Answer',
            value: currectAnswerCount,
          },
          {
            key: 'Your Wrong Answer',
            value: answer.length - currectAnswerCount,
          },
        ]);
        if (currectAnswerCount - (answer.length - currectAnswerCount) >= 0) {
          setTaskScore(
            `${currectAnswerCount - (answer.length - currectAnswerCount)} / ${acceptbleAnswer.length
            }`,
          );
        } else if (
          currectAnswerCount - (answer.length - currectAnswerCount) <
          0
        ) {
          setTaskScore(`0 / ${acceptbleAnswer.length}`);
        }
        setSkillTable([
          {
            key: type,
            value: `${scoreToFixed(score[type])}/${scoreToFixed(
              skillMaxScore[type],
            )}`,
          },
        ]);
        if (useragent.isDesktop)
          setHeaderTitle('Multiple Choice (Choose Multiple Answers)');
        else setHeaderTitle('(R) MC (Choose Multiple  Answers)');
        setSkillDescription(
          `In real exam, each MC (CSA) question may contribute up to ${scoreToFixed(score[type])} marks toward Reading. (Asm: 2Q total)`,
        );
        setGuid(
          '<strong>1</strong> Each correct response<br/><strong>-1</strong> Each incorrect response<br/><strong>0</strong> Minimum score<br/><br/> <strong>Partial credit (for each correct response. Points deducted for incorrect options chosen)</strong>',
        );
        setMaxScore(15);
        break;
      case 'ReorderParagraph':
        setTraitsTable([
          {
            key: 'Number of Paragraphs',
            value: questionOption.length,
          },
          {
            key: `Available Pairs (${questionOption.length - 1})`,
            value: availablePair,
          },
          {
            key: 'Your Pairs',
            value: yourPair,
          },
        ]);
        setSkillTable([
          {
            key: 'Reading',
            value: `${scoreToFixed(score.Reading)}/${scoreToFixed(
              skillMaxScore.Reading,
            )}`,
          },
        ]);
        setSkillDescription(
          `In real exam, each re-order paragraph question may contribute up to ${scoreToFixed(skillMaxScore.Reading)} marks toward Reading. (Asm: 2Q total)`,
        );
        setGuid(
          '<strong>1</strong> Each pair of correct adjacent textboxes<br/><strong>0</strong> Minimum score<br/><br/> <strong>Partial credit (for each correctly ordered, adjacent pair)</strong>',
        );
        setHeaderTitle('Re-Order Paragraph');
        setMaxScore(15);
        break;
      case 'ReadingAndWritingFillInTheBlanks':
        setTraitsTable([
          {
            key: 'Number of Blanks',
            value: acceptbleAnswer.length,
          },
          {
            key: 'Your Correct Blanks',
            value: currectAnswerCount,
          },
          {
            key: 'Your Wrong Blanks',
            value: answer.length - currectAnswerCount,
          },
        ]);
        setTaskScore(currectAnswerCount);
        setSkillTable([
          {
            key: 'Reading',
            value: `${scoreToFixed(score.Reading)}/${scoreToFixed(
              skillMaxScore.Reading,
            )}`,
          },
          {
            key: 'Writing',
            value: `${scoreToFixed(score.Writing)}/${scoreToFixed(
              skillMaxScore.Writing,
            )}`,
          },
        ]);
        if (useragent.isDesktop) {
          setHeaderTitle('Reading and Writing Fill in the Blanks');
        } else {
          setHeaderTitle('R&W Fill in the Blanks');
        }
        setSkillDescription(
          `In real exam, each R&W (FIB) question may contribute up to ${scoreToFixed(skillMaxScore.Reading)} & ${scoreToFixed(skillMaxScore.Writing)} marks toward reading and writing resp. (Asm: 6Q total)`,
        );
        setGuid(
          '<strong>1</strong> Each pair of correct adjacent textboxes<br/><strong>0</strong> Minimum score<br/><br/> <strong>Partial credit (for each correctly completed blank)</strong>',
        );
        setMaxScore(15);
        break;
      case 'ReadingFillInTheBlanks':
        setTraitsTable([
          {
            key: 'Number of Blanks',
            value: acceptbleAnswer.length,
          },
          {
            key: 'Your Correct Blanks',
            value: currectAnswerCount,
          },
          {
            key: 'Your Wrong Blanks',
            value: answer.length - currectAnswerCount,
          },
        ]);
        setTaskScore(currectAnswerCount);
        setSkillTable([
          {
            key: 'Reading',
            value: `${scoreToFixed(score.Reading)}/${scoreToFixed(
              skillMaxScore.Reading,
            )}`,
          },
        ]);
        if (useragent.isDesktop) setHeaderTitle('Fill in the Blanks');
        else setHeaderTitle('(R) Fill in the Blanks');
        setSkillDescription(
          `In real exam, each fill in the blanks score question may contribute up to ${scoreToFixed(skillMaxScore.Reading)} marks toward Reading. (Asm:4Q total)`,
        );
        setGuid(
          '<strong>1</strong> Each correctly completed blank<br/><strong>0</strong> Minimum score<br/><br/> <strong>Partial credit (for each correctly completed blank)</strong>',
        );
        setMaxScore(15);
        break;
      case 'ListeningMultipleChoiceSingleAnswer':
        setTraitsTable([
          {
            key: 'Number of Options',
            value: questionOption.length,
          },
          {
            key: 'Correct Answers Available',
            value: 1,
          },
          {
            key: 'Your Correct Answer',
            value: currectAnswerCount,
          },
          {
            key: 'Your Wrong Answer',
            value: currectAnswerCount - 1 < 0 ? 0 : currectAnswerCount - 1,
          },
        ]);
        setTaskScore(currectAnswerCount);
        setSkillTable([
          {
            key: type,
            value: `${scoreToFixed(score[type])}/${scoreToFixed(
              skillMaxScore[type],
            )}`,
          },
        ]);
        if (useragent.isDesktop)
          setHeaderTitle('Multiple Choice (Choose Single Answer)');
        else setHeaderTitle('(L) MC (Choose Single Answer)');
        setSkillDescription(
          `In real exam, each MC (CSA) question may contribute up to ${scoreToFixed(skillMaxScore[type])} marks toward Listening. (Asm: 2Q total)`,
        );
        setGuid(
          '<strong>1</strong> Correct response<br/><strong>0</strong> Incorrect response<br/><br/> <strong>(There is no negative marking)</strong>',
        );
        setMaxScore(15);
        break;
      case 'ListeningMultipleChoiceMultipleAnswer':
        setTraitsTable([
          {
            key: 'Number of Options',
            value: questionOption.length,
          },
          {
            key: 'Correct Answers Available',
            value: acceptbleAnswer.length,
          },
          {
            key: 'Your Correct Answer',
            value: currectAnswerCount,
          },
          {
            key: 'Your Wrong Answer',
            value: answer.length - currectAnswerCount,
          },
        ]);
        if (useragent.isDesktop)
          setHeaderTitle('Multiple Choice (Choose Multiple Answers)');
        else setHeaderTitle('(L) MC (Choose Multiple Answers)');
        if (currectAnswerCount - (answer.length - currectAnswerCount) >= 0) {
          setTaskScore(
            `${currectAnswerCount - (answer.length - currectAnswerCount)} / ${acceptbleAnswer.length
            }`,
          );
        } else if (
          currectAnswerCount - (answer.length - currectAnswerCount) <
          0
        ) {
          setTaskScore(`0 / ${acceptbleAnswer.length}`);
        }
        setSkillTable([
          {
            key: type,
            value: `${scoreToFixed(score[type])}/${scoreToFixed(
              skillMaxScore[type],
            )}`,
          },
        ]);
        setSkillDescription(
          `In real exam, each MC (CMAs) question may contribute up to ${scoreToFixed(skillMaxScore[type])} marks toward listening. (Asm: 2Q total)`,
        );
        setGuid(
          '<strong>1</strong> Each correct response<br/><strong>-1</strong> Each incorrect response<br/><strong>0</strong> Minimum score<br/><br/> <strong>Partial credit (for each correct response. Points deducted for incorrect options chosen)</strong>',
        );
        setMaxScore(15);
        break;
      case 'ListeningFillInTheBlanks':
        setTraitsTable([
          {
            key: 'Number of Blanks',
            value: acceptbleAnswer.length,
          },
          {
            key: 'Your Correct Blanks',
            value: currectAnswerCount,
          },
          {
            key: 'Your Wrong Blanks',
            value: answer.length - currectAnswerCount,
          },
        ]);
        setTaskScore(currectAnswerCount);
        setSkillTable([
          {
            key: 'Listening',
            value: `${scoreToFixed(score.Listening)}/${scoreToFixed(
              skillMaxScore.Listening,
            )}`,
          },
          {
            key: 'Writing',
            value: `${scoreToFixed(score.Writing)}/${scoreToFixed(
              skillMaxScore.Writing,
            )}`,
          },
        ]);
        if (useragent.isDesktop) setHeaderTitle('Fill in the Blanks');
        else setHeaderTitle('(L) Fill in the Blanks');
        setSkillDescription(
          `In real exam, each FIB question may contribute up to ${scoreToFixed(skillMaxScore.Listening)} and ${scoreToFixed(skillMaxScore.Writing)} marks toward listening and writing resp. (Asm: 2Q total)`,
        );
        setGuid(
          '<strong>1</strong> Each correct word spelled correctly<br/><strong>0</strong> Minimum score<br/><br/> <strong>Partial credit (for each correct work spelled correctly)</strong>',
        );
        setMaxScore(15);
        break;
      case 'HighlightCorrectSummary':
        setTraitsTable([
          {
            key: 'Number of Summaries',
            value: questionOption.length,
          },
          {
            key: 'Your Correct Summary',
            value: currectAnswerCount,
          },
          {
            key: 'Your Wrong Summary',
            value: currectAnswerCount - 1 < 0 ? 1 : currectAnswerCount - 1,
          },
        ]);
        setTaskScore(currectAnswerCount);
        setSkillTable([
          {
            key: 'Listening',
            value: `${scoreToFixed(score.Listening)}/${scoreToFixed(
              skillMaxScore.Listening,
            )}`,
          },
          {
            key: 'Reading',
            value: `${scoreToFixed(score.Reading)}/${scoreToFixed(
              skillMaxScore.Reading,
            )}`,
          },
        ]);
        setHeaderTitle('Highlight Correct Summary');
        setSkillDescription(
          `In real exam, each highlight correct summary question may contribute up to ${scoreToFixed(skillMaxScore.Listening)} and ${scoreToFixed(skillMaxScore.Reading)} marks toward listening and reading respectively. (Asm: 2Q total)`,
        );
        setGuid(
          '<strong>Correct/incorrect</strong><br/><br/><strong>1</strong> Correct response<br/><strong>0</strong> Incorrect response<br/>',
        );
        setMaxScore(15);
        break;
      case 'SelectMissingWord':
        setTraitsTable([
          {
            key: 'Number of Options',
            value: questionOption.length,
          },
          {
            key: 'Your Correct Option',
            value: currectAnswerCount,
          },
          {
            key: 'Your Incorrect Option',
            value: currectAnswerCount - 1 < 0 ? 1 : currectAnswerCount - 1,
          },
        ]);
        setHeaderTitle('Select Missing Word');
        setTaskScore(currectAnswerCount);
        setSkillTable([
          {
            key: type,
            value: `${scoreToFixed(score[type])}/${scoreToFixed(
              skillMaxScore[type],
            )}`,
          },
        ]);
        setSkillDescription(
          `In real exam, each SMW score question may contribute up to ${scoreToFixed(skillMaxScore[type])} marks toward Listening. (Asm: 2Q total)`,
        );
        setGuid(
          '<strong>Correct/incorrect</strong><br/><br/><strong>1</strong> Correct response<br/><strong>0</strong> Incorrect response<br/>',
        );
        setMaxScore(15);
        break;
      case 'HighlightIncorrectWords':
        setTraitsTable([
          {
            key: 'Number of Incorrect Words',
            value: acceptbleAnswer.length,
          },
          {
            key: 'Your Correct Highlighted Words',
            value: currectAnswerCount,
          },
          {
            key: 'Wrong Words',
            value: answer.length - currectAnswerCount,
          },
        ]);
        if (currectAnswerCount - (answer.length - currectAnswerCount) >= 0) {
          setTaskScore(
            currectAnswerCount - (answer.length - currectAnswerCount),
          );
        } else if (
          currectAnswerCount - (answer.length - currectAnswerCount) <
          0
        ) {
          setTaskScore(0);
        }
        setSkillTable([
          {
            key: 'Listening',
            value: `${scoreToFixed(score.Listening)}/${scoreToFixed(
              skillMaxScore.Listening,
            )}`,
          },
          {
            key: 'Reading',
            value: `${scoreToFixed(score.Reading)}/${scoreToFixed(
              skillMaxScore.Reading,
            )}`,
          },
        ]);
        setHeaderTitle('Highlight Incorrect Words');
        setSkillDescription(
          `In real exam, each highlight incorrect word question may contribute up to ${scoreToFixed(skillMaxScore.Listening)} and ${scoreToFixed(skillMaxScore.Reading)} marks toward listening and reading respectively. (Asm: 2Q total)`,
        );
        setGuid(
          '<strong>1</strong> Each correctly completed blank<br/><strong>-1</strong> Each incorrect word<br/><strong>0</strong> Minimum score<br/><br/> <strong>Partial credit (For each word. Points deducted for incorrect options chosen)</strong>',
        );
        setMaxScore(15);
        break;
      default:
        break;
    }
  }, [score, category, currectAnswerCount, availablePair, yourPair]);
  return (
    <>
      {loading ? (<LoadingAIIndicator isScore={true} />
      ) : (
        <>
          {useragent.isDesktop ? (

            <>
              <Grid container justify="space-between" className={clsx(classes.header, classes.mainHeader)}>
                <Typography className={classes.headerText}>{type}</Typography>
                <Typography className={classes.headerText}>
                  {headerTitle}
                </Typography>
                <Typography className={classes.headerText} />
              </Grid>


              <Grid container className={classes.body}>
                <Grid container spacing={3} justify="space-between">
                  <Grid item xs={12} md={6}>
                    <table className={classes.table}>
                      <tbody>
                        {traitsTable.map((item, index) => (
                          <tr className={index === 0 ? classes.firstTr : ''}>
                            <td>{item.key}</td>
                            <td
                              style={{ textAlign: 'center' }}
                              className={
                                category !== 'ReorderParagraph'
                                  ? classes.scoreCol
                                  : ''
                              }>
                              {item.value}
                            </td>
                          </tr>
                        ))}
                        <tr style={{ background: '#EDF0F2' }}>
                          <td style={{ fontWeight: 700 }}>
                            Score
                            <GuidTooltip
                              title={
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: `${guid}`,
                                  }}
                                />
                              }
                              arrow
                              placement="bottom-start"
                              style={{ backgroundColor: '#fff' }}>
                              <button
                                type="button"
                                style={{
                                  border: 'none',
                                  outline: 'none',
                                  background: 'transparent',
                                }}>
                                <InfoIcon color={theme.palette.primary.main} />
                              </button>
                            </GuidTooltip>
                          </td>
                          <td style={{ fontWeight: 700, textAlign: 'center' }}>
                            {taskScore}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <table className={classes.table}>
                      <thead>
                        <tr className={classes.tableHeader}>
                          <th scope="col">Communicative Skills</th>
                          <th scope="col" className={classes.scoreCol}>
                            Score
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {skillTable.map((item) => (
                          <tr>
                            <td>{item.key}</td>
                            <td className={classes.scoreCol}>{item.value}</td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan="2" style={{ padding: 0 }}>
                            <div className={classes.scoreSection}>
                              <Typography className={classes.skillDescription}>
                                {skillDescription}
                              </Typography>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  justify="space-between"
                  style={{ marginTop: '22px' }}>
                  {category === 'ReadingMultipleChoiceSingleAnswer' ||
                    category === 'ListeningMultipleChoiceSingleAnswer' ||
                    category === 'SelectMissingWord' ? (
                    <Grid item xs={12} md={6}>
                      <table className={classes.table}>
                        <thead className={classes.tableHeader}>
                          <tr>
                            <th colSpan="2" style={{ paddingLeft: '30px' }}>
                              Chosen Options
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{answer}</td>
                            <td className={classes.scoreCol}>
                              {currectAnswerCount ? (
                                <SvgCircleCheck
                                  color={theme.palette.primary.main}
                                />
                              ) : (
                                <SvgCircleCross color="red" />
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Grid>
                  ) : null}
                  {category === 'ReadingMultipleChoiceMultipleAnswer' ||
                    category === 'ListeningMultipleChoiceMultipleAnswer' ? (
                    <Grid item xs={12} md={6}>
                      <table className={classes.table}>
                        <thead className={classes.tableHeader}>
                          <tr>
                            <th colSpan="2" style={{ paddingLeft: '30px' }}>
                              Chosen Options
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {answer.map((item) => (
                            <tr>
                              <td>{item}</td>
                              <td className={classes.scoreCol}>
                                {checkOptionStatus(item) ? (
                                  <SvgCircleCheck
                                    color={theme.palette.primary.main}
                                  />
                                ) : (
                                  <SvgCircleCross color="red" />
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Grid>
                  ) : null}
                  {category === 'HighlightIncorrectWords' ? (
                    <Grid item xs={12} md={6}>
                      <table className={classes.table}>
                        <thead className={classes.tableHeader}>
                          <tr>
                            <th className={classes.scoreCol}>Number</th>
                            <th colSpan="2">Highlighted Words</th>
                          </tr>
                        </thead>
                        <tbody>
                          {answer.map((item, index) => (
                            <tr>
                              <td className={classes.scoreCol}>{index + 1}</td>
                              <td>{item.value}</td>
                              <td className={classes.scoreCol}>
                                {checkHighlightIncorrectWords(
                                  item.value,
                                  item.key,
                                ) ? (
                                  <SvgCircleCheck
                                    color={theme.palette.primary.main}
                                  />
                                ) : (
                                  <SvgCircleCross color="red" />
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Grid>
                  ) : null}
                  {category === 'ListeningFillInTheBlanks' ||
                    category === 'ReadingAndWritingFillInTheBlanks' ? (
                    <Grid item xs={12} md={6}>
                      <table className={classes.table}>
                        <thead className={classes.tableHeader}>
                          <tr>
                            <th className={classes.scoreCol}>Number</th>
                            <th colSpan="2">Chosen Words</th>
                          </tr>
                        </thead>
                        <tbody>
                          {answer.map((item, index) => (
                            <tr>
                              <td className={classes.scoreCol}>{index + 1}</td>
                              <td>{item}</td>
                              <td className={classes.scoreCol}>
                                {checkFillInTheBlank(item, index) ? (
                                  <SvgCircleCheck
                                    color={theme.palette.primary.main}
                                  />
                                ) : (
                                  <SvgCircleCross color="red" />
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Grid>
                  ) : null}
                  {category === 'ReadingFillInTheBlanks' ? (
                    <Grid item xs={12} md={6}>
                      <table className={classes.table}>
                        <thead className={classes.tableHeader}>
                          <tr>
                            <th className={classes.scoreCol}>Number</th>
                            <th colSpan="2">Chosen Words</th>
                          </tr>
                        </thead>
                        <tbody>
                          {answer.map((item, index) => (
                            <tr>
                              <td className={classes.scoreCol}>{index + 1}</td>
                              <td>{item}</td>
                              <td className={classes.scoreCol}>
                                {checkFillInTheBlank(item, index) ? (
                                  <SvgCircleCheck
                                    color={theme.palette.primary.main}
                                  />
                                ) : (
                                  <SvgCircleCross color="red" />
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Grid>
                  ) : null}
                  {category === 'HighlightCorrectSummary' ? (
                    <Grid item xs={12}>
                      <table className={classes.table}>
                        <thead className={classes.tableHeader}>
                          <tr>
                            <th colSpan="2">Chosen Summary</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{answer}</td>
                            <td>
                              {currectAnswerCount ? (
                                <SvgCircleCheck
                                  color={theme.palette.primary.main}
                                />
                              ) : (
                                <SvgCircleCross color="red" />
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Grid>
                  ) : null}
                  {category === 'ReorderParagraph' ? (
                    <Grid item xs={12}>
                      <table className={classes.table}>
                        <thead className={classes.tableHeader}>
                          <tr>
                            <th style={{ width: '127px', textAlign: 'center' }}>
                              Your Order
                            </th>
                            <th>Sentence</th>
                          </tr>
                        </thead>
                        <tbody>
                          {answer.map((item) => (
                            <tr>
                              <td style={{ width: '127px', textAlign: 'center' }}>
                                {item}
                              </td>
                              <td>{questionOption[item - 1]}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Grid>
                  ) : null}
                </Grid>
                <Grid container justify="flex-end" style={{ marginTop: '20px' }}>
                  <Button
                    className={classes.upgradeButton}
                    onClick={() =>
                      router.push({
                        pathname: '/subscribe',
                      })
                    }>
                    Upgrade
                  </Button>
                  <Button className={classes.close} onClick={onClose}>
                    Close
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid container justify="center" className={classes.headerMobile}>
                <Typography className={classes.headerTextMobile}>
                  {headerTitle}
                </Typography>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <button
                    type="button"
                    className={clsx(classes.tabButton, {
                      [classes.activeTab]: activeTab === 0,
                    })}
                    onClick={() => setActiveTab(0)}>
                    Scoring
                  </button>
                </Grid>
                <Grid item xs={6}>
                  <button
                    type="button"
                    className={clsx(classes.tabButton, {
                      [classes.activeTab]: activeTab === 1,
                    })}
                    onClick={() => setActiveTab(1)}>
                    Communicative Skills
                  </button>
                </Grid>
              </Grid>
              <Grid container className={classes.mobileBody}>
                <Grid container style={{ marginTop: '25px' }}>
                  {activeTab === 0 ? (
                    <Grid item xs={12}>
                      <table className={classes.table}>
                        <tbody>
                          {traitsTable.map((item, index) => (
                            <tr className={index === 0 ? classes.firstTr : ''}>
                              <td>{item.key}</td>
                              <td className={classes.scoreCol}>{item.value}</td>
                            </tr>
                          ))}
                          <tr style={{ background: '#EDF0F2' }}>
                            <td style={{ fontWeight: 700 }}>
                              Score{' '}
                              <button
                                onClick={() => setOpenModal(true)}
                                type="button"
                                style={{
                                  border: 'none',
                                  outline: 'none',
                                  background: 'transparent',
                                }}>
                                <InfoIcon color={theme.palette.primary.main} />
                              </button>
                            </td>
                            <td
                              style={{ fontWeight: 700 }}
                              className={classes.scoreCol}>
                              {taskScore}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <table className={classes.table}>
                        <thead>
                          <tr className={classes.tableHeader}>
                            <th scope="col">Communicative Skills</th>
                            <th scope="col" className={classes.scoreCol}>
                              Score
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {skillTable.map((item) => (
                            <tr>
                              <td>{item.key}</td>
                              <td className={classes.scoreCol}>{item.value}</td>
                            </tr>
                          ))}
                          <tr>
                            <td colSpan="2" style={{ padding: 0 }}>
                              <div className={classes.scoreSection}>
                                <Typography className={classes.skillDescription}>
                                  {skillDescription}
                                </Typography>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Grid>
                  )}
                </Grid>
                <Grid container justify="space-between" style={{ marginTop: '22px' }}>
                  {category === 'ReadingMultipleChoiceSingleAnswer' ||
                    category === 'ListeningMultipleChoiceSingleAnswer' ||
                    category === 'SelectMissingWord' ? (
                    <Grid item xs={12}>
                      <table className={classes.table}>
                        <thead className={classes.tableHeader}>
                          <tr>
                            <th colSpan="2" style={{ paddingLeft: '30px' }}>
                              Chosen Options
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{answer}</td>
                            <td>
                              {currectAnswerCount ? (
                                <SvgCircleCheck
                                  color={theme.palette.primary.main}
                                />
                              ) : (
                                <SvgCircleCross color="red" />
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Grid>
                  ) : null}
                  {category === 'ReadingMultipleChoiceMultipleAnswer' ||
                    category === 'ListeningMultipleChoiceMultipleAnswer' ? (
                    <Grid item xs={12}>
                      <table className={classes.table}>
                        <thead className={classes.tableHeader}>
                          <tr>
                            <th colSpan="2" style={{ paddingLeft: '30px' }}>
                              Chosen Options
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {answer.map((item) => (
                            <tr>
                              <td>{item}</td>
                              <td>
                                {checkOptionStatus(item) ? (
                                  <SvgCircleCheck
                                    color={theme.palette.primary.main}
                                  />
                                ) : (
                                  <SvgCircleCross color="red" />
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Grid>
                  ) : null}
                  {category === 'HighlightIncorrectWords' ? (
                    <Grid item xs={12}>
                      <table className={classes.table}>
                        <thead className={classes.tableHeader}>
                          <tr>
                            <th>Number</th>
                            <th colSpan="2">Highlighted Words</th>
                          </tr>
                        </thead>
                        <tbody>
                          {answer.map((item, index) => (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item.value}</td>
                              <td>
                                {checkHighlightIncorrectWords(
                                  item.value,
                                  item.key,
                                ) ? (
                                  <SvgCircleCheck
                                    color={theme.palette.primary.main}
                                  />
                                ) : (
                                  <SvgCircleCross color="red" />
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Grid>
                  ) : null}
                  {category === 'ListeningFillInTheBlanks' ||
                    category === 'ReadingAndWritingFillInTheBlanks' ? (
                    <Grid item xs={12}>
                      <table className={classes.table}>
                        <thead className={classes.tableHeader}>
                          <tr>
                            <th>Number</th>
                            <th colSpan="2">Chosen Words</th>
                          </tr>
                        </thead>
                        <tbody>
                          {answer.map((item, index) => (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item}</td>
                              <td>
                                {checkFillInTheBlank(item, index) ? (
                                  <SvgCircleCheck
                                    color={theme.palette.primary.main}
                                  />
                                ) : (
                                  <SvgCircleCross color="red" />
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Grid>
                  ) : null}
                  {category === 'ReadingFillInTheBlanks' ? (
                    <Grid item xs={12}>
                      <table className={classes.table}>
                        <thead className={classes.tableHeader}>
                          <tr>
                            <th>Number</th>
                            <th colSpan="2">Chosen Words</th>
                          </tr>
                        </thead>
                        <tbody>
                          {answer.map((item, index) => (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item}</td>
                              <td>
                                {checkFillInTheBlank(item, index) ? (
                                  <SvgCircleCheck
                                    color={theme.palette.primary.main}
                                  />
                                ) : (
                                  <SvgCircleCross color="red" />
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Grid>
                  ) : null}
                  {category === 'HighlightCorrectSummary' ? (
                    <Grid item xs={12}>
                      <table className={classes.table}>
                        <thead className={classes.tableHeader}>
                          <tr>
                            <th colSpan="2">Chosen Summary</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{answer}</td>
                            <td>
                              {currectAnswerCount ? (
                                <SvgCircleCheck
                                  color={theme.palette.primary.main}
                                />
                              ) : (
                                <SvgCircleCross color="red" />
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Grid>
                  ) : null}
                  {category === 'ReorderParagraph' ? (
                    <Grid item xs={12}>
                      <table className={classes.table}>
                        <thead className={classes.tableHeader}>
                          <tr>
                            <th style={{ textAlign: 'center' }}>Sentence</th>
                          </tr>
                        </thead>
                        <tbody>
                          {answer.map((item) => (
                            <tr>
                              <td style={{ position: 'relative' }}>
                                {questionOption[item - 1]}
                                <span
                                  style={{
                                    position: 'absolute',
                                    bottom: '2px',
                                    right: '5px',
                                    color: theme.palette.primary.main,
                                  }}>
                                  {item}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Grid>
                  ) : null}
                </Grid>
                <Grid container justify="center" style={{ marginTop: '25px' }}>
                  <Grid item xs={10}>
                    <Button
                      className={classes.upgradeButtonMobile}
                      onClick={() =>
                        router.push({
                          pathname: '/subscribe',
                        })
                      }>
                      Upgrade
                    </Button>
                  </Grid>
                  <Grid item xs={10}>
                    <button
                      type="button"
                      className={classes.closeButton}
                      onClick={onClose}>
                      Close
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          <Grid>
            <Dialog
              open={openModal}
              onClose={() => setOpenModal(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogContent>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${guid}`,
                  }}
                />
              </DialogContent>
            </Dialog>
          </Grid>


        </>
      )}
    </>
  );
};

AiScore.propTypes = {
  category: PropTypes.string,
  score: PropTypes.number,
  onClose: PropTypes.func,
  answer: PropTypes.string,
  t: PropTypes.func,
  title: PropTypes.string,
  useragent: PropTypes.object,
};

AiScore.defaultProps = {
  category: '',
  score: 0,
  onClose: () => { },
  answer: '',
  t: () => { },
  title: '',
  useragent: {},
};

export default AiScore;
