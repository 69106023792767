import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import CrossIcon from 'components/Icons/gen/Cross';
import CheckIcon from 'components/Icons/gen/Check';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  textFieldContainer: {
    marginBottom: theme.spacing(3),
    marginTop : "10px"
  },
  draggableItemsContainer: {
    background: '#edf0f2',
    borderTop: `1px solid ${theme.palette.primary.main}`,
    minHeight: 90,
  },
  draggableItem: {
    fontWeight: 600,
    color: 'white',
    textAlign: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    background: theme.palette.primary.main,
    margin: '4px 8px',
    '&:hover': {
      cursor: 'pointer',
    },
    borderRadius: 3,
    // display: 'inline-flex',
    // justifyContent: 'center',
    // alignItems: 'center'
  },
  answerPlaceholder: {
    minWidth: theme.spacing(14),
    background: '#EDF0F2',
    minHeight: theme.spacing(4),
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRadius: 3,
    padding: 4,
    // '&::before': {
    //   content: '"a"',
    //   visibility: 'hidden',
    // },
  },
  answerPlaceholderAnswer: {
    minWidth: theme.spacing(14),
    background: '#EDF0F2',
    minHeight: theme.spacing(4),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    display: 'inline-flex !important',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 3,
    verticalAlign: 'middle',
  },
  questionText: {
    lineHeight: 2.1,
    fontSize: '18px',
    fontWeight: 400,
    color: '#353535',
  },
  optionsDroppable: {
    display: 'contents',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  check: {
    color: 'white',
  },
  cross: {
    color: 'red',
  },
  wrongAnswer: {
    color: 'red',
  },
  answer: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
    fontWeight: 600,
  },
}));
const ReadingFillInTheBlanks = ({
  t,
  question,
  onAnswered,
  answer,
  submittedQuestion,
  questionAnswer,
  mockAnswer,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const {options} = question?.question_data;

  const keyValues = options?.map((item) => ({
    key: `${item}${Math.random() * 1000}`,
    value: item,
  }));

  const optionsLength = options.length;
  const backendColumns = {
    source: {
      items: keyValues,
    },
    target: {
      items: [],
    },
  };

  const splitted = question?.question_data?.text.split('<blank/>');
  const blankCount = (question?.question_data?.text.match(/<blank/g) || [])
    .length;

  const [practiceAnswer, setPracticeAnswer] = useState(backendColumns);

  const onDragEnd = (result) => {
    const {source, destination} = result;
    const prevPracticeAnswer = JSON.parse(JSON.stringify(practiceAnswer));
    if (submittedQuestion) return;
    if (!destination && source.droppableId === 'options') return;
    if (
      destination &&
      source.droppableId === 'options' &&
      !prevPracticeAnswer.target.items[Number(destination.droppableId)]
    ) {
      prevPracticeAnswer.source.items.splice(source.index, 1);
      prevPracticeAnswer.target.items[destination.droppableId] = {
        ...practiceAnswer.source.items[source.index],
      };
    } else if (
      destination &&
      source.droppableId === 'options' &&
      prevPracticeAnswer.target.items[Number(destination.droppableId)]
    ) {
      prevPracticeAnswer.source.items.push({
        ...practiceAnswer.target.items[destination.index],
      });
      prevPracticeAnswer.target.items[destination.droppableId] = {
        ...practiceAnswer.source.items[source.index],
      };
      prevPracticeAnswer.source.items.splice(source.index, 1);
    } else if (!destination && source.droppableId !== 'options') {
      prevPracticeAnswer.source.items.push({
        ...practiceAnswer.target.items[source.index],
      });
      prevPracticeAnswer.target.items[source.index] = null;
    } else if (
      destination &&
      source.droppableId !== 'options' &&
      !prevPracticeAnswer.target.items[destination.droppableId]
    ) {
      prevPracticeAnswer.target.items[destination.droppableId] = {
        ...practiceAnswer.target.items[source.index],
      };
      prevPracticeAnswer.target.items[source.index] = null;
    } else if (
      destination &&
      source.droppableId !== 'options' &&
      source.droppableId === destination.droppableId
    ) {
      return;
    } else {
      prevPracticeAnswer.source.items.push({
        ...practiceAnswer.target.items[destination.index],
      });
      prevPracticeAnswer.target.items[destination.index] = {
        ...prevPracticeAnswer.target.items[source.index],
      };
      prevPracticeAnswer.target.items[source.index] = null;
    }
    setPracticeAnswer(prevPracticeAnswer);
  };

  useEffect(() => {
    const filledAnswer = [];
    for (let i = 0; i < blankCount; i += 1) {
      if (!practiceAnswer.target.items[i]) filledAnswer.push('');
      else filledAnswer.push(practiceAnswer.target.items[i].value);
    }
    // for (let i = 0; i < blankCount; i += 1) {
    //   if (practiceAnswer.target.items[i])
    //     filledAnswer.push(practiceAnswer.target.items[i].value);
    // }
    onAnswered(filledAnswer);
  }, [practiceAnswer]);

  useEffect(() => {
    if (mockAnswer && answer && answer.length > 0) {
      setPracticeAnswer({
        target: {
          items: [...answer],
        },
      });
    }
  }, [answer]);
  return (
    <Grid container justify="center">
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid item xs={12} className={classes.textFieldContainer}>
          {splitted.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <Typography
                  variant="body1"
                  display="inline"
                  className={classes.questionText}>
                  {item}
                </Typography>
                {index + 1 <= blankCount && (
                  <span
                    className={classes.answerPlaceholderAnswer}
                    style={{background: 'transparent', padding: 0, margin: 0}}>
                    <Droppable
                      droppableId={`${index}`}
                      key={`${index}`}
                      style={{width: '100%'}}>
                      {(provided, snapshot) => {
                        if (
                          practiceAnswer.target.items[index] !== null &&
                          practiceAnswer.target.items[index] !== undefined
                        ) {
                          return (
                            <span
                              style={{
                                verticalAlign: 'bottom',
                              }}
                              ref={provided.innerRef}
                              {...provided.droppableProps}>
                              <Draggable
                                key={practiceAnswer.target.items[index].key}
                                draggableId={
                                  practiceAnswer.target.items[index].key
                                }
                                index={index}>
                                {(provided, snapshot) => (
                                  <span
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={clsx(
                                      classes.answerPlaceholderAnswer,
                                      {
                                        [classes.wrongAnswer]:
                                          submittedQuestion &&
                                          answer &&
                                          questionAnswer &&
                                          questionAnswer[
                                            'acceptable-answers'
                                          ] &&
                                          questionAnswer['acceptable-answers']
                                            .data &&
                                          answer[index]
                                            ?.replace(/[^\w\s]/gi, '')
                                            .toLowerCase() !==
                                            questionAnswer[
                                              'acceptable-answers'
                                            ].data[0].answer[index]
                                              ?.replace(/[^\w\s]/gi, '')
                                              .toLowerCase(),
                                      },
                                    )}
                                    style={{
                                      backgroundColor: snapshot.isDragging
                                        ? '#666'
                                        : theme.palette.primary.main,
                                      ...provided.draggableProps.style,
                                    }}>
                                    {submittedQuestion &&
                                      (answer &&
                                      questionAnswer &&
                                      questionAnswer['acceptable-answers'] &&
                                      questionAnswer['acceptable-answers']
                                        .data &&
                                      answer[index]
                                        ?.replace(/[^\w\s]/gi, '')
                                        .toLowerCase() ===
                                        questionAnswer[
                                          'acceptable-answers'
                                        ].data[0].answer[index]
                                          ?.replace(/[^\w\s]/gi, '')
                                          .toLowerCase() ? (
                                        <CheckIcon
                                          color="#fff"
                                          className={clsx(
                                            classes.icon,
                                            classes.check,
                                          )}
                                        />
                                      ) : (
                                        <CrossIcon
                                          color="#f00"
                                          className={clsx(
                                            classes.icon,
                                            classes.cross,
                                          )}
                                        />
                                      ))}
                                    {!mockAnswer
                                      ? practiceAnswer.target.items[index].value
                                      : practiceAnswer.target.items[index]}
                                  </span>
                                )}
                              </Draggable>
                            </span>
                          );
                        }
                        return (
                          <span
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className={classes.answerPlaceholder}
                            style={{
                              background: snapshot.isDraggingOver
                                ? 'lightblue'
                                : '#EDF0F2',
                              verticalAlign: 'middle',
                              transition: 'none',
                            }}>
                            {practiceAnswer[index]}
                            {provided.placeholder}
                          </span>
                        );
                      }}
                    </Droppable>
                    {submittedQuestion && (
                      <Typography
                        className={classes.answer}
                        variant="body1"
                        color="primary"
                        display="inline">
                        {questionAnswer &&
                          questionAnswer['acceptable-answers'] &&
                          questionAnswer['acceptable-answers'].data &&
                          questionAnswer['acceptable-answers'].data[0].answer[
                            index
                          ]}
                      </Typography>
                    )}
                  </span>
                )}
              </React.Fragment>
            );
          })}
        </Grid>
        {!mockAnswer ? (
          <Grid
            item
            xs={12}
            container
            className={classes.draggableItemsContainer}
            justify="center"
            alignItems="center">
            <Droppable droppableId="options" key="options">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={classes.optionsDroppable}>
                  {practiceAnswer.source.items.map((option, index) => (
                    <Draggable
                      key={option.key}
                      draggableId={option.key}
                      index={index}>
                      {(provided, snapshot) => {
                        return (
                          <span
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={classes.draggableItem}
                            style={{
                              backgroundColor: snapshot.isDragging
                                ? '#666'
                                : theme.palette.primary.main,
                              ...provided.draggableProps.style,
                            }}>
                            {option.value}
                          </span>
                        );
                      }}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Grid>
        ) : null}
      </DragDropContext>
    </Grid>
  );
};

ReadingFillInTheBlanks.propTypes = {
  t: PropTypes.func.isRequired,
  question: PropTypes.shape({
    question_name: PropTypes.string.isRequired,
    question_data: PropTypes.shape().isRequired,
    question_type: PropTypes.string.isRequired,
  }).isRequired,
  onAnswered: PropTypes.func.isRequired,
  answer: PropTypes.array,
  submittedQuestion: PropTypes.bool.isRequired,
  questionAnswer: PropTypes.shape(),
};
ReadingFillInTheBlanks.defaultProps = {
  answer: [],
  questionAnswer: null,
};

export default ReadingFillInTheBlanks;
