import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  board: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    width: '100%',
    height: '100%',
  },
  boardMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    width: '100%',
    height: '100%',
  },
  sourceHeaderContainer: {
    background: '#C4C4C4',
    textAlign: 'center',
    height: theme.spacing(5),
    color: 'black',
    flex: 1,
    width: '100%',
    maxHeight: theme.spacing(6),
    borderRadius: '3px 3px 0 0',
    '& h2': {
      fontSize: '20px',
      fontWeight: 600,
      margin: '8px 0',
    },
  },
  targetHeaderContainer: {
    background: theme.palette.primary.main,
    textAlign: 'center',
    height: theme.spacing(5),
    color: 'white',
    flex: 1,
    width: '100%',
    // maxHeight: theme.spacing(6),
    maxHeight: 46,
    borderRadius: '3px 3px 0 0',
    '& h2': {
      fontSize: '20px',
      fontWeight: 600,
      margin: '8px 0',
    },
  },
  listItem: {
    border: `1px solid #ddd`,
    borderRadius: 3,
    marginBottom: theme.spacing(3),
    position: 'relative',
    minHeight: '111px',
  },
  list: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  orderCount: {
    position: 'absolute',
    background: theme.palette.primary.main,
    color: 'white',
    fontWeight: 'bold',
    right: 0,
    bottom: 0,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
const ReorderParagraphPractice = ({
  question,
  answer,
  questionAnswer,
  submittedQuestion,
  useragent,
}) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" justify="space-around">
      {submittedQuestion && questionAnswer && (
        <>
          <Paper
            className={
              useragent.isDesktop ? classes.board : classes.boardMobile
            }
            elevation={0}>
            <div className={classes.sourceHeaderContainer}>
              <h2 style={{margin: '8px 0'}}>Your Answer</h2>
            </div>
            <div
              style={{
                margin: 0,
                maxWidth: '100%',
                width: '100%',
                border: '1px solid #C4C4C4',
                borderTop: 'none',
              }}>
              <div
                style={{
                  background: 'white',
                  padding: 4,
                  minHeight: 500,
                  width: '100%',
                }}>
                <List className={classes.list}>
                  {answer && answer.length == 1 && answer[0] == 'Not Answered' && 
                  (
                    <Typography style={{textAlign : 'center'}}>
                      Not Answered
                    </Typography>
                  )}
                  {answer &&
                    answer.length > 0 && answer[0] != "Not Answered" && 
                    answer.map((a) => (
                      <ListItem key={a} className={classes.listItem}>
                        <ListItemText
                          primary={question?.question_data?.options[a - 1]}
                        />
                        <span className={classes.orderCount}>{a}</span>
                      </ListItem>
                    ))}
                </List>
              </div>
            </div>
          </Paper>
          <Paper
            className={clsx({
              [classes.board]: useragent.isDesktop,
              [classes.boardMobile]: useragent.isMobile || useragent.isTablet,
            })}
            elevation={0}>
            <div className={classes.targetHeaderContainer}>
              <h2>Correct Answer</h2>
            </div>
            <div
              style={{
                margin: 0,
                maxWidth: '100%',
                width: '100%',
                border: '1px solid #C4C4C4',
                borderTop: 'none',
              }}>
              <div
                style={{
                  background: 'white',
                  padding: 4,
                  minHeight: 500,
                  width: '100%',
                }}>
                <List className={classes.list}>
                  {questionAnswer &&
                    questionAnswer['acceptable-answers'] &&
                    questionAnswer['acceptable-answers'].data &&
                    questionAnswer['acceptable-answers'].data[0] &&
                    questionAnswer['acceptable-answers'].data[0].answer.map(
                      (a) => (
                        <ListItem key={a} className={classes.listItem}>
                          <ListItemText
                            primary={question?.question_data?.options[a - 1]}
                          />
                          <span className={classes.orderCount}>{a}</span>
                        </ListItem>
                      ),
                    )}
                </List>
              </div>
            </div>
          </Paper>
        </>
      )}
    </Grid>
  );
};

ReorderParagraphPractice.propTypes = {
  t: PropTypes.func.isRequired,
  question: PropTypes.shape({
    question_name: PropTypes.string.isRequired,
    question_data: PropTypes.shape().isRequired,
    question_media: PropTypes.array.isRequired,
  }).isRequired,
  onAnswered: PropTypes.func.isRequired,
  answer: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  submittedQuestion: PropTypes.bool.isRequired,
  questionAnswer: PropTypes.shape(),
  useragent: PropTypes.object,
};
ReorderParagraphPractice.defaultProps = {
  answer: null,
  questionAnswer: null,
  useragent: {},
};

export default ReorderParagraphPractice;
