import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {withUserAgent} from 'next-useragent';
import {useSnackbar} from 'notistack';
import {
  answerQuestion,
  getQuestion,
  getComments,
  getMyHistory,
  clearAnswers,
  getSharedResponse,
  practiceScoringAction,
  getAiCallStatus,
} from 'core/lib/adapters/redux/actions/practice';
import {useDispatch, useSelector} from 'react-redux';
import Error from 'next/error';
import Dialog from '@material-ui/core/Dialog';
import {makeStyles} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import ReadAloudPractice from 'components/Practice/Speaking/ReadAloud/ReadAloudPractice';
import ListenAndRepeatPractice from 'components/ListenAndRepeatPractice';
import DescribeImagePractice from 'components/DescribeImagePractice';
import WritingPractice from 'components/WritingPractice';
import RWFillInTheBlanksPractice from 'components/RWFillInTheBlanksPractice';
import ReadingChoicePractice from 'components/ReadingChoicePractice';
import ReorderParagraphPractice from 'components/ReorderParagraphPractice';
import ReadingFillInTheBlanks from 'components/ReadingFillInTheBlanks';
import ModelAnswer from 'components/ModelAnswer';
import SummarizeSpokenText from 'components/Practice/Listening/SummarizeSpokenText';
import HighlightIncorrectWords from 'components/Practice/Listening/HighlightIncorrectWords';
import ListeningFillInTheBlanks from 'components/Practice/Listening/FillInTheBlanks';
import SelectMissingWord from 'components/Practice/Listening/SelectMissingWord';
import NonAiScore from 'components/NonAiScore/nonAi';
import AiScore from 'components/AiScore/ai';
import {useInterval} from 'utils/utils';
import CourseNavigator from './CourseNavigator';
import CourseNavigatorM from './CourseNavigatorM';
import LoadingIndicator from '../../LoadingIndicator';

const useStyles = makeStyles(() => ({
  scoreModal: {
    maxWidth: '1053px',
  },
}));

// function ChooseQuestionTypeHoc({question, tabName, useragent, id, navigatorType}){
function ChooseQuestionTypeHoc({
  tabName,
  useragent,
  id,
  navigatorType,
  onNextNav,
  onPrevNav,
  onExitNav,
  multipleQuestionsFinalData,
  isInResultPage,
}) {
  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  if (!id) return <Error statusCode={404} />;
  const [scoreInterval, setScoreInterval] = useState(null);
  const [mount, setMount] = useState(true);
  const [answer, setAnswer] = useState(null);
  const [nonAiModal, setNonAiModal] = useState(false);
  const [aiModal, setAiModal] = useState(false);
  const [shareDisabled, setShareDisabled] = useState(true);
  const [aiSubmit, setAiSubmit] = useState(false);
  const question =
    useSelector((state) => state.questions.selectedQuestion) || {};
  const [bookmarked, setBookmarked] = useState(null);
  const [BookmarkListener, setBookmarkListener] = useState(false);
  const [shareSubmit, setShareSubmit] = useState(false);
  const [reportSubmitted, setReportSubmitted] = useState(false);
  const [stopTimer, setStopTimer] = useState(false);
  const [pausePlay, setPausePlay] = useState(false);
  const [resetTimer, setResetTimer] = useState(false);
  const [isRecodingOn , setIsRecordingOn] = useState(false)
  const[isRecordingStop , setIsRecordingStop] = useState(false)
  const[isSubmit  , setIsSubmit] = useState(false);
  const bookmark = useSelector((state) => state.questions.bookmark);
  const questionAnswer =
    useSelector((state) => state.questions.answers[id]) || null;
  const shareResponse = useSelector(
    (state) => state.questions.sharedResponse[id] || {},
  );
  const submittingQuestion = question.isAnswering;
  const loadingQuestion =
    Object.keys(question)?.length === 0 || question.loading || false;
  const loadingShare = shareResponse.loading || false;
  const auth = useSelector((state) => state.auth);
  const score = useSelector((state) => state.questions.score.scores) || {};
  const {
    total_ai_requests: totalAiRequests,
    remaining_ai_requests: remainingAiRequests,
  } = useSelector((state) => state.questions.score);
  const skillMaxScore =
    useSelector((state) => state.questions.score.max_scores) || {};
  const scoreLoading =
    useSelector((state) => state.questions.score.loading) || false;
  const aiCallId =
    useSelector((state) => state.questions.score.ai_call_id) || null;
  const aiCallStatus = useSelector((state) => state.questions.aiCall);

  const answerHandler = () => {
    const questionCategoriesWithRecorder = ['RepeatSentence','RetellLecture','AnswerShortQuestion', 'ReadAloud','DescribeImage']
    if(questionCategoriesWithRecorder.includes(question.question_type)){
      setIsRecordingStop(true)
    }
    setIsSubmit(true)
    // if (
    //   question.question_type === 'SummarizeWrittenText' &&
    //   answer === question.question_data.text
    // ) {
    //   enqueueSnackbar(t('notif:doNotCopyTheQuestionToYourAnswer'), {
    //     variant: 'error',
    //     ...(useragent.isDesktop
    //       ? {}
    //       : {anchorOrigin: {horizontal: 'center', vertical: 'top'}}),
    //   });
    // } else {
    //   setStopTimer(true);
    //   setPausePlay(true);
    //   dispatch(
    //     answerQuestion({
    //       question_id: question.id,
    //       userId: auth.user.id,
    //       answer,
    //       questionType: question.question_type,
    //     }),
    //   );
    //   setShareDisabled(false);
    // }
  };
  useEffect(()=>{
    console.log("answer is in useEffct 😎😎" , answer)
    if(answer && isSubmit){
      if (
        question.question_type === 'SummarizeWrittenText' &&
        answer === question.question_data.text
      ) {
        enqueueSnackbar(t('notif:doNotCopyTheQuestionToYourAnswer'), {
          variant: 'error',
          ...(useragent.isDesktop
            ? {}
            : {anchorOrigin: {horizontal: 'center', vertical: 'top'}}),
        });
      } else {
        setStopTimer(true);
        setPausePlay(true);
        dispatch(
          answerQuestion({
            question_id: question.id,
            userId: auth.user.id,
            answer,
            questionType: question.question_type,
          }),
        );
        setShareDisabled(false);
      }
    }
  },[answer , isSubmit])


  const getAnswerScoreHandler = () => {
    dispatch(practiceScoringAction({id: questionAnswer?.answer?.id}));
    setAiSubmit(true);
    setScoreInterval(5 * 1000);
    enqueueSnackbar(
      t('notif:yourRequestHasBeenSubmittedPleaseWaitUntilTheScoreIsReady'),
      {
        key: 'aiScoreNotice',
        variant: 'info',
        // persist: true,
        ...(useragent.isDesktop
          ? {}
          : {anchorOrigin: {horizontal: 'center', vertical: 'top'}}),
      },
    );
  };

  const redoHandler = () => {
    setStopTimer(false);
    setPausePlay(false);
    setAiSubmit(false);
    setMount(false);
    setResetTimer(true);
    setAnswer(null);
    dispatch(clearAnswers());
    setScoreInterval(null);
    closeSnackbar('aiScoreNotice');
  };

  const playerStartAfter = (type) => {
    switch (type) {
      case 'RepeatSentence':
      case 'RetellLecture':
      case 'AnswerShortQuestion':
        return 3;
      case 'SummarizeSpokenText':
        return 12;
      case 'ListeningMultipleChoiceMultipleAnswer':
        return 7;
      case 'ListeningFillInTheBlanks':
        return 7;
      case 'HighlightCorrectSummary':
        return 10;
      case 'ListeningMultipleChoiceSingleAnswer':
        return 5;
      case 'SelectMissingWord':
        return 7;
      case 'HighlightIncorrectWords':
        return 10;
      case 'WriteFromDictation':
        return 7;
      default:
        return 3;
    }
  };

  const getStartInForPractice = (type) => {
    switch (type) {
      case 'RepeatSentence':
        return 3;
      case 'RetellLecture':
        return 10;
      case 'AnswerShortQuestion':
        return 3;
      case 'DescribeImage':
        return 25;
      default:
        return 10;
    }
  };

  const getEndInForPractice = (type) => {
    switch (type) {
      case 'RepeatSentence':
        return 15;
      case 'RetellLecture':
        return 40;
      case 'AnswerShortQuestion':
        return 10;
      case 'DescribeImage':
        return 40;
      default:
        return 40;
    }
  };

  const isAnswerDisabled = () => {
    if (question.question_type === 'ListeningFillInTheBlanks') {
      return (
        !answer ||
        answer.length < 1 ||
        answer.filter((item) => item !== '').length === 0
      );
    }
    if (
      question.question_type === 'ReadingAndWritingFillInTheBlanks' ||
      question.question_type === 'ReadingFillInTheBlanks' ||
      question.question_type === 'ReorderParagraph' ||
      question.question_type === 'ReadingMultipleChoiceMultipleAnswer' ||
      question.question_type === 'HighlightIncorrectWords' ||
      question.question_type === 'ListeningMultipleChoiceMultipleAnswer'
    ) {
      return (
        !answer ||
        answer.length < 1 ||
        answer.filter((item) => item === '').length !== 0 ||
        answer.filter((item) => item !== '').length === 0
      );
    }
    if (!answer) return true;
    if (
      (question.question_type === 'WriteEssay' ||
        question.question_type === 'SummarizeWrittenText' ||
        question.question_type === 'SummarizeSpokenText' ||
        question.question_type === 'WriteFromDictation') &&
      answer === ''
    )
      return true;
    return false;
  };

  const shouldRenderModelAnswer = () => {
    return (
      question &&
      questionAnswer &&
      [
        'SummarizeWrittenText',
        'WriteEssay',
        'ReadAloud',
        'RepeatSentence',
        'DescribeImage',
        'RetellLecture',
        'AnswerShortQuestion',
        'SummarizeSpokenText',
        'WriteFromDictation',
      ].indexOf(question.question_type) >= 0
    );
  };

  const [multiplePageData, setMultiplePageData] = useState([]);
  const [pendingSubmittedNextPage, setPendingSubmittedNextPage] = useState(
    false,
  );
  const handleNextPage = () => {
    answerHandler();
    setPendingSubmittedNextPage(true);
  };
  useEffect(() => {
    if (
      questionAnswer &&
      !reportSubmitted &&
      !submittingQuestion &&
      !question.isReporting &&
      pendingSubmittedNextPage
    ) {
      setMultiplePageData((s) => {
        return [...s, {answer: questionAnswer, question}];
      });
      // TODO fix this, I was force to write it like this so that it return current
      // render data not the next render
      multipleQuestionsFinalData([
        ...multiplePageData,
        {answer: questionAnswer, question},
      ]);
      onNextNav();
      setAnswer('');
      dispatch(clearAnswers());
      setPendingSubmittedNextPage(false);
    }
  }, [question, reportSubmitted, submittingQuestion, questionAnswer]);

  useEffect(() => {
    if (multiplePageData[question.id]) {
      setAnswer(multiplePageData[question.id]);
    }
  }, [question]);

  const handlePrevPage = () => {
    multiplePageData.pop();
    setMultiplePageData(multiplePageData);
    multipleQuestionsFinalData(multiplePageData);
    onPrevNav();
  };

  useInterval(() => {
    setAiSubmit(true);
    dispatch(getAiCallStatus({id: aiCallId}));
  }, scoreInterval);

  useEffect(() => {
    if (reportSubmitted && !question.isReporting) {
      enqueueSnackbar(t('notif:yourReportSubmittedSuccessfully'), {
        variant: 'success',
        ...(useragent.isDesktop
          ? {}
          : {anchorOrigin: {horizontal: 'center', vertical: 'top'}}),
      });

      setTimeout(() => {
        setReportSubmitted(false);
      }, 200);
    }
  }, [question]);

  useEffect(() => {
    if (
      aiCallStatus &&
      !aiCallStatus.loading &&
      aiCallStatus.is_completed &&
      !aiCallStatus.error
    ) {
      setScoreInterval(null);
      dispatch(practiceScoringAction({id: questionAnswer?.answer?.id}));
    } else if (
      aiCallStatus &&
      !aiCallStatus.loading &&
      aiCallStatus.is_completed &&
      aiCallStatus.error
    ) {
      // setScoreInterval(null);
      // enqueueSnackbar(aiCallStatus.error, {variant: 'error'});
    }
  }, [aiCallStatus]);

  useEffect(() => {
    if (aiSubmit && !scoreLoading && !score.failed) {
      setAiSubmit(false);
      setScoreInterval(null);
      if (question.corrector === 'AI') setAiModal(true);
      else setNonAiModal(true);
      closeSnackbar('aiScoreNotice');
    }
    if (aiSubmit && !scoreLoading && score.failed) {
      setScoreInterval(null);
      closeSnackbar('aiScoreNotice');
      setAiSubmit(false);
      if (useragent.isDesktop) {
        enqueueSnackbar(score.error.e, {variant: 'error'});
      } else {
        enqueueSnackbar(score.error.e, {
          variant: 'error',
          anchorOrigin: {horizontal: 'center', vertical: 'top'},
        });
      }
    }
  }, [score]);

  useEffect(() => {
    if (BookmarkListener && !bookmark.loading) {
      setBookmarkListener(false);
      if (!bookmark.error) {
        setBookmarked(!bookmarked);
      }
    }
  }, [bookmarked, bookmark]);

  useEffect(() => {
    if (!mount) {
      setMount(true);
    }
  }, [mount]);

  useEffect(() => {
    if (
      questionAnswer &&
      questionAnswer.answer &&
      questionAnswer.answer.answer?.length > 0
    ) {
      dispatch(getMyHistory({id}));
      if (!submittingQuestion) {
        enqueueSnackbar(t('notif:yourAnswerHasBeenSuccessfullySubmitted'), {
          variant: 'success',
          ...(useragent.isDesktop
            ? {}
            : {anchorOrigin: {horizontal: 'center', vertical: 'top'}}),
        });
      }
    }
  }, [questionAnswer]);

  useEffect(() => {
    if (question.id) {
      setAnswer('');
      dispatch(clearAnswers());
    }
  }, [question.id]);

  useEffect(() => {
    // setAnswer(null);
    // dispatch(clearAnswers());
    dispatch(getQuestion({id}));
    dispatch(getComments({id}));
    dispatch(getMyHistory({id}));
    dispatch(getSharedResponse({id}));
  }, [id]);

  useEffect(() => {
    if (
      shareSubmit &&
      shareDisabled &&
      questionAnswer &&
      questionAnswer.answer &&
      loadingShare
    ) {
      enqueueSnackbar(t('notif:yourAnswerHasBeenSuccessfullyShared'), {
        variant: 'success',
        ...(useragent.isDesktop
          ? {}
          : {anchorOrigin: {horizontal: 'center', vertical: 'top'}}),
      });
    }
  }, [shareSubmit, shareDisabled, loadingShare]);
  const onSaveFinish = (audioProfile)=>{
    setAnswer(audioProfile)
  }

  const setRecorderStatus = ()=>{
    setIsRecordingOn(true)
  }

  useEffect(() => {
    if (resetTimer) {
      setTimeout(() => {
        setResetTimer(false);
      }, 2 * 1000);
    }
  }, [resetTimer]);

  function chooseType() {
    switch (question.question_type) {
      case 'ReadAloud':
        return (
          <ReadAloudPractice
            question={question}
            t={t}
            onAnswered={(a) => setAnswer(a)}
            answer={answer}
            useragent={useragent}
            onSaveFinish={onSaveFinish}
            isRecordingStop = {isRecordingStop}
            setRecorderStatus={setRecorderStatus}
          />
        );
      case 'RepeatSentence':
      case 'RetellLecture':
      case 'AnswerShortQuestion':
        return (
          <ListenAndRepeatPractice
            question={question}
            t={t}
            onAnswered={(a) => setAnswer(a)}
            answer={answer}
            startIn={getStartInForPractice(question.question_type)}
            endIn={getEndInForPractice(question.question_type)}
            useragent={useragent}
            pausePlay={pausePlay}
            playerStartAfter={playerStartAfter(question.question_type)}
            showStartTimer
            setRecorderStatus={setRecorderStatus}
            isRecordingStop = {isRecordingStop}
          />
        );
      case 'DescribeImage':
        return (
          <DescribeImagePractice
            question={question}
            t={t}
            onAnswered={(a) => setAnswer(a)}
            answer={answer}
            useragent={useragent}
            setRecorderStatus={setRecorderStatus}
            isRecordingStop = {isRecordingStop}
          />
        );
      case 'SummarizeWrittenText':
      case 'WriteEssay':
        return (
          <WritingPractice
            question={question}
            t={t}
            onAnswered={(a) => setAnswer(a)}
            answer={answer}
            submittedQuestion={!!questionAnswer}
            useragent={useragent}
            stopTimer={stopTimer}
          />
        );
      case 'ReadingAndWritingFillInTheBlanks':
        return (
          <RWFillInTheBlanksPractice
            t={t}
            onAnswered={(a) => setAnswer(a)}
            question={question}
            answer={answer}
            questionAnswer={questionAnswer}
            submittedQuestion={
              (questionAnswer &&
                questionAnswer.answer &&
                questionAnswer.answer.answer?.length > 0) ||
              false
            }
          />
        );
      case 'ReadingMultipleChoiceMultipleAnswer':
      case 'ReadingMultipleChoiceSingleAnswer':
      case 'HighlightCorrectSummary':
      case 'ListeningMultipleChoiceMultipleAnswer':
      case 'ListeningMultipleChoiceSingleAnswer':
        return (
          <ReadingChoicePractice
            t={t}
            answer={answer}
            onAnswered={(a) => setAnswer(a)}
            useragent={useragent}
            pausePlay={pausePlay}
            isSingle={
              question.question_type === 'ReadingMultipleChoiceSingleAnswer' ||
              question.question_type === 'HighlightCorrectSummary' ||
              question.question_type === 'ListeningMultipleChoiceSingleAnswer'
            }
            question={question}
            questionAnswer={questionAnswer}
            submittedQuestion={
              (questionAnswer &&
                questionAnswer.answer &&
                questionAnswer.answer.answer?.length > 0) ||
              false
            }
            isHighlight={question.question_type === 'HighlightCorrectSummary'}
            isListening={
              question.question_type === 'HighlightCorrectSummary' ||
              question.question_type ===
                'ListeningMultipleChoiceMultipleAnswer' ||
              question.question_type === 'ListeningMultipleChoiceSingleAnswer'
            }
            playerStartAfter={playerStartAfter(question.question_type)}
            showStartTimer
          />
        );
      case 'ReorderParagraph':
        return (
          <ReorderParagraphPractice
            t={t}
            answer={answer}
            onAnswered={(a) => setAnswer(a)}
            question={question}
            questionAnswer={questionAnswer}
            submittedQuestion={
              (questionAnswer &&
                questionAnswer.answer &&
                questionAnswer.answer.answer?.length > 0) ||
              false
            }
          />
        );
      case 'ReadingFillInTheBlanks':
        return (
          <ReadingFillInTheBlanks
            t={t}
            answer={answer}
            onAnswered={(a) => setAnswer(a)}
            question={question}
            questionAnswer={questionAnswer}
            useragent={useragent}
            submittedQuestion={
              (questionAnswer &&
                questionAnswer.answer &&
                questionAnswer.answer.answer?.length > 0) ||
              false
            }
          />
        );
      case 'SummarizeSpokenText':
      case 'WriteFromDictation':
        return (
          <SummarizeSpokenText
            question={question}
            t={t}
            onAnswered={(a) => setAnswer(a)}
            answer={answer}
            useragent={useragent}
            pausePlay={pausePlay}
            submittedQuestion={!!questionAnswer}
            showCounter={question.question_type === 'SummarizeSpokenText'}
            playerStartAfter={playerStartAfter(question.question_type)}
            showStartTimer
          />
        );
      case 'ListeningFillInTheBlanks':
        return (
          <ListeningFillInTheBlanks
            t={t}
            onAnswered={(a) => setAnswer(a)}
            question={question}
            answer={answer}
            questionAnswer={questionAnswer}
            useragent={useragent}
            pausePlay={pausePlay}
            submittedQuestion={
              (questionAnswer &&
                questionAnswer.answer &&
                questionAnswer.answer.answer?.length > 0) ||
              false
            }
            playerStartAfter={playerStartAfter(question.question_type)}
            showStartTimer
          />
        );
      case 'SelectMissingWord':
        return (
          <SelectMissingWord
            t={t}
            answer={answer}
            onAnswered={(a) => setAnswer(a)}
            question={question}
            questionAnswer={questionAnswer}
            useragent={useragent}
            pausePlay={pausePlay}
            submittedQuestion={
              (questionAnswer &&
                questionAnswer.answer &&
                questionAnswer.answer.answer?.length > 0) ||
              false
            }
            playerStartAfter={playerStartAfter(question.question_type)}
            showStartTimer
          />
        );
      case 'HighlightIncorrectWords':
        return (
          <HighlightIncorrectWords
            t={t}
            answer={answer}
            onAnswered={(a) => setAnswer(a)}
            question={question}
            questionAnswer={questionAnswer}
            useragent={useragent}
            pausePlay={pausePlay}
            submittedQuestion={
              (questionAnswer &&
                questionAnswer.answer &&
                questionAnswer.answer.answer?.length > 0) ||
              false
            }
            playerStartAfter={playerStartAfter(question.question_type)}
            showStartTimer
          />
        );
      default:
        return <LoadingIndicator />;
    }
  }
  const getRepeatSentenceRecodingState =(questionType)=>{
    const questionCategoriesWithRecorder = ['RepeatSentence', 'RetellLecture', 'AnswerShortQuestion', 'ReadAloud','DescribeImage']
    if(questionCategoriesWithRecorder.includes(questionType)){
      if(isRecodingOn){
        return false;
      }
    }
    return true;
  }
  return (
    <>
      {!loadingQuestion && !question.failed && mount && chooseType()}
      {navigatorType === 'singleQuestion' && useragent.isDesktop && (
        <CourseNavigator
          t={t}
          navigating={false}
          // onExit={props.onExit}
          navType="singleQuestion"
          prevDisabled={false}
          nextDisabled={false}
          onExit={onExitNav}
          onRedo={redoHandler}
          onAnswer={answerHandler}
          onAI={getAnswerScoreHandler}
          aiDisabled={isAnswerDisabled() || !questionAnswer}
          aiLoading={scoreLoading}
          answerDisabled={((isAnswerDisabled() || questionAnswer !== null) && getRepeatSentenceRecodingState(question.question_type)) || isSubmit}
          submittingAnswer={submittingQuestion}
          aiCorrector={question.corrector === 'AI'}
        />
      )}

      {(navigatorType === 'multipleQuestionLastPage' ||
        navigatorType === 'multipleQuestion') &&
        useragent.isDesktop && (
          <CourseNavigator
            t={t}
            navigating={false}
            navType={navigatorType}
            // onPrev={onPrevNav}
            onPrev={handlePrevPage}
            // onNext={onNextNav}
            onNext={handleNextPage}
            onAnswer={answerHandler}
            prevDisabled={false}
            nextDisabled={isAnswerDisabled()}
            saveAndExit
            onExit={onExitNav}
            // onAnswer={answerHandler}
            onRedo={redoHandler}
            onAI={getAnswerScoreHandler}
            aiDisabled={isAnswerDisabled() || !questionAnswer}
            aiLoading={scoreLoading}
            // answerDisabled={isAnswerDisabled() || questionAnswer !== null}
            answerDisabled={((isAnswerDisabled() || questionAnswer !== null) && getRepeatSentenceRecodingState(question.question_type)) || isSubmit}


            submittingAnswer={submittingQuestion}
            // shareResponse={shareResponseHandler}
            // showShareResponse={
            // question.corrector === 'AI' &&
            // question.question_type !== 'WriteFromDictation'
            // }
            shareResponseDisabled={shareDisabled}
            aiCorrector={question.corrector === 'AI'}
          />
        )}

      {(useragent.isMobile || useragent.isTablet) && (
        <CourseNavigatorM
          t={t}
          navigating={false}
          // onExit={props.onExit}
          navType={navigatorType}
          // onPrev={() => {
          // questionNavigationHandler('prev');
          // }}
          onPrev={handlePrevPage}
          // onNext={onNextNav}
          onNext={handleNextPage}
          prevDisabled={false}
          nextDisabled={false}
          onExit={onExitNav}
          // onAnswer={()=>{}}
          onAnswer={answerHandler}
          onRedo={redoHandler}
          onAI={getAnswerScoreHandler}
          aiDisabled={isAnswerDisabled() || !questionAnswer}
          aiLoading={scoreLoading}
          // answerDisabled={isAnswerDisabled() || questionAnswer !== null}
          answerDisabled={((isAnswerDisabled() || questionAnswer !== null) && getRepeatSentenceRecodingState(question.question_type)) || isSubmit}
          submittingAnswer={submittingQuestion}
          // shareResponse={shareResponseHandler}
          // showShareResponse={
          //   question.corrector === 'AI' &&
          //   question.question_type !== 'WriteFromDictation'
          // }
          shareResponseDisabled={shareDisabled}
          aiCorrector={question.corrector === 'AI'}
        />
      )}

      {shouldRenderModelAnswer() && (
        <ModelAnswer
          t={t}
          question={question}
          questionAnswer={questionAnswer}
          type={question.question_type}
          isModelAnswer={
            [
              'SummarizeWrittenText',
              'WriteEssay',
              'ReadAloud',
              'RepeatSentence',
              'DescribeImage',
              'RetellLecture',
              'AnswerShortQuestion',
              'SummarizeSpokenText',
              'WriteFromDictation',
            ].indexOf(question.question_type) >= 0
          }
          transcript={
            questionAnswer &&
            questionAnswer['acceptable-answers'] &&
            questionAnswer['acceptable-answers'] &&
            questionAnswer['acceptable-answers'].data &&
            questionAnswer['acceptable-answers'].data[0] &&
            questionAnswer['acceptable-answers'].data[0].answer_meta &&
            questionAnswer['acceptable-answers'].data[0].answer_meta.transcript
          }
        />
      )}

      <Dialog
        classes={{
          paper: classes.scoreModal,
        }}
        fullWidth
        maxWidth="lg"
        fullScreen={!useragent.isDesktop}
        open={nonAiModal}
        onClose={() => setNonAiModal(!nonAiModal)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        <NonAiScore
          type={question.category}
          category={question.question_type}
          onClose={() => setNonAiModal(false)}
          score={score}
          answer={
            questionAnswer &&
            questionAnswer.answer &&
            questionAnswer.answer.answer
          }
          acceptbleAnswer={
            questionAnswer &&
            questionAnswer['acceptable-answers'] &&
            questionAnswer['acceptable-answers'].data &&
            questionAnswer['acceptable-answers'].data[0] &&
            questionAnswer['acceptable-answers'].data[0].answer
          }
          title={question.question_name}
          useragent={useragent}
          skillMaxScore={skillMaxScore}
          questionOption={
            question && question.question_data && question.question_data.options
          }
        />
      </Dialog>
      <Dialog
        classes={{
          paper: classes.scoreModal,
        }}
        maxWidth="lg"
        fullWidth={false}
        fullScreen={!useragent.isDesktop}
        open={aiModal}
        onClose={() => setAiModal(!aiModal)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        <AiScore
          type={question.category}
          category={question.question_type}
          onClose={() => setAiModal(false)}
          score={score}
          totalAIRequests={totalAiRequests}
          remainingAIRequests={remainingAiRequests}
          answer={
            questionAnswer &&
            questionAnswer.answer &&
            questionAnswer.answer.answer
          }
          transcript={
            (questionAnswer &&
              questionAnswer['acceptable-answers'] &&
              questionAnswer['acceptable-answers'].data &&
              questionAnswer['acceptable-answers'].data[0] &&
              questionAnswer['acceptable-answers'].data[0].answer_meta &&
              questionAnswer['acceptable-answers'].data[0].answer_meta
                .transcript !== 'null' &&
              questionAnswer['acceptable-answers'].data[0].answer_meta
                .transcript) ||
            (questionAnswer &&
              questionAnswer.question_meta &&
              questionAnswer.question_meta.transcript)
          }
          title={question.question_name}
          useragent={useragent}
          skillMaxScore={skillMaxScore}
        />
      </Dialog>
    </>
  );
}

ChooseQuestionTypeHoc.propTypes = {
  useUserAgent: PropTypes.object,
  navigatorType: PropTypes.string.isRequired,
  onNextNav: PropTypes.funtion,
  onPrevNav: PropTypes.function,
  multipleQuestionsFinalData: PropTypes.function,
  isInResultPage: PropTypes.bool,
  useragent: PropTypes.object,
};

ChooseQuestionTypeHoc.defaultProps = {
  useragent: {},
};

export default withUserAgent(ChooseQuestionTypeHoc);
