import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import { blob2ObjectUrl } from 'utils/utils';
import Player from './Player';
import { Box, Button } from '@material-ui/core';
import { recordIntro, recordStopSave } from '../../core/src/adapters/redux/actions';
import { cannotSkipModelAction, recordingStoppedAction } from 'core/lib/adapters/redux/actions/modelAction';

const useStyles = makeStyles((theme) => ({
  counter: {
    color: 'red',
    fontWeight: 600,
    marginLeft: theme.spacing(0.5),
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px'
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  recordIconStarted: {
    marginBottom: theme.spacing(1),
    background: theme.palette.primary.main,
    padding: theme.spacing(0.5),
    borderRadius: '50%',
    cursor: 'pointer',
  },
  recordedIcon: {
    marginBottom: theme.spacing(1),
    background: '#C4C4C4',
    padding: theme.spacing(0.5),
    borderRadius: '50%',
  },
  currentStatus: {
    fontSize: '16px',
    display: 'block',
    color: '#000',
    fontWeight: '500',
    marginBottom: '6px'
  },
  clickToStart: {
    fontSize: '14px',
    color: '#353535',
    lineHeight: '24px',
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
  },

  recordingTitle: {
    fontSize: '14px',
    color: '#999',
    fontWeight: 600,
    // marginBottom: '12px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px'
    },
  },
  loadingProgress: {
    height: theme.spacing(1),
    width: '100%',
    marginTop: '24px',
    borderRadius: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(1),
    },
  },
  colorPrimary: {},
  barColorPrimary: {
    backgroundColor: '#0275D8',
    backgroundImage:
      'linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)',
    backgroundSize: '1rem 1rem',
  },
  permissionText: {
    fontSize: '15px',
    fontWeight: 400,
    color: '#353535',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px'
    }
  },
  recordBg: {
    backgroundColor: theme.palette.background.default,
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    width: '100%',
    maxWidth: ' 320px',
    // minHeight: "55px",
    height: 'auto',
    // borderRadius: '50px',
    // padding: '2px 22px',
    alignItems: 'center',
    display: 'grid',
    padding: '30px 20px',
    [theme.breakpoints.down('sm')]: {
      minHeight: '40px',
      padding: '8px 12px',
    },
  },
  mainGapFlex: {
    // display: 'flex',
    gap: '12px',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      gap: '6px'
    },
  },
  iconPausePlay: {
    height: '55px',
    width: '55px',
    marginRight: '12px',
    backgroundColor: theme.palette.primary.main,
    transition: '0.3s ease-in-out',
    '&:hover': {
      backgroundColor: '#0A3564',
      opacity: 0.9
    },
    [theme.breakpoints.down('sm')]: {
      height: '40px',
      width: '40px',
      marginRight: '8px',
    },
  },

  actionBtn: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
    marginTop: '24px',
    // marginBottom: '24px'
  },
  actionBtnStyle: {
    padding: '10px 18px'
  },
  gridPadding: {
    padding: '2px 0px',
    [theme.breakpoints.down('md')]: {
      padding: '2px 12px',
    }
  }
}));
const Recorder = ({
  t,
  startIn,
  endIn,
  onSaveFinish,
  answer,
  question,
  startTimer,
  waitingForPlayer,
  onRecordStart,
  useragent,
  mock,
  playBeepSound,
  playback,
  recordTimeShow,
  setIsSkip,
  firebaseModal,
  setIsTotalIntroTime,
  setIsStopNextQuestion,
  isStopNextQuestion,
  isRecordingStop,
  step,
  isIntoducationScreen,
  recordedAnswer,
  isPlayerStatusRecord
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [recorderTime, setRecorderTimer] = useState(answer && answer !== 'null' ? 0 : startIn);
  const [remainingTime, setRemainingTime] = useState(answer && answer !== 'null' ? 0 : endIn);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [recorded, setRecorded] = useState(!!(answer && answer !== 'null'));
  const [recording, setRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false)
  const [audioSrc, setAudioSrc] = useState(answer);
  const [recorder, setRecorder] = useState(null);
  const [playerRecord, setplayerRecord] = useState(true)
  const startRecord = () => {
    setRecorderTimer(0);
    const snd = new Audio('/beep_.wav');
    snd.play();
  };

  const stopRecording = () => {
    setRecorded(true);
    setRecording(false);
    recorder.stop();
  };

  useEffect(() => {
    if (isRecordingStop) {
      stopRecording()
    }
  }, [isRecordingStop])


  useEffect(() => {
    import('audio-recorder-polyfill').then((module) => {
      window.MediaRecorder = module.default;
    });
  }, []);

  useEffect(() => {
    if (recorderTime == 0) {
      setIsSkip && setIsSkip(true)
    }
    else {
      setIsSkip && setIsSkip(false)
    }
  }, [recorderTime])

  useEffect(() => {
    if (!startTimer && waitingForPlayer) return;
    if (recorderTime !== 0) {
      const timer = setTimeout(() => {
        setRecorderTimer((prev) => prev - 1);
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
    if (recorderTime === 0 && !recorded && !recording) {
      if (playBeepSound) {
        const snd = new Audio('/beep_.wav');
        snd.play();
        snd.addEventListener('ended', () => {
          setTimeout(() => {
            navigator.mediaDevices
              .getUserMedia({ audio: true })
              .then((s) => {
                const mime = ['audio/wav', 'audio/webm', 'audio/ogg'].filter(
                  MediaRecorder.isTypeSupported,
                )[0];
                setRecording(true);
                const recorderI = new MediaRecorder(s, {
                  mimeType: mime,
                });
                setRecorder(recorderI);
                recorderI.addEventListener('dataavailable', (e) => {
                  setAudioSrc(blob2ObjectUrl(e.data));
                });
                recorderI.start();
                onRecordStart();
              })
              .catch((error) => {
                console.log('error', error);
                if (
                  error.message === 'Permission denied' ||
                  error.name === 'NotAllowedError'
                )
                  setPermissionDenied(true);
              });
          }, 500);
        });
      } else {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((s) => {
            const mime = ['audio/wav', 'audio/webm', 'audio/ogg'].filter(
              MediaRecorder.isTypeSupported,
            )[0];
            setRecording(true);
            const recorderI = new MediaRecorder(s, {
              mimeType: mime,
            });
            setRecorder(recorderI);
            recorderI.addEventListener('dataavailable', (e) => {
              setAudioSrc(blob2ObjectUrl(e.data));
            });
            recorderI.start();
            onRecordStart();
          })
          .catch((error) => {
            console.log('error', error);
            if (
              error.message === 'Permission denied' ||
              error.name === 'NotAllowedError'
            )
              setPermissionDenied(true);
          });
      }
    }
  }, [recorderTime, startTimer, firebaseModal]);

  useEffect(() => {
    if (!recorded && recording && remainingTime !== 0) {
      const timer = setTimeout(() => {
        setRemainingTime((prev) => prev - 1);
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [remainingTime, recording]);

  useEffect(() => {
    if (remainingTime === 0) {
      stopRecording();
    }
  }, [recorded, recording, remainingTime])

  useEffect(() => {
    if (recorded && !recording && audioSrc) onSaveFinish(audioSrc);
  }, [recorded, recording, audioSrc]);

  useEffect(() => {
    if (setIsTotalIntroTime) {
      setIsTotalIntroTime(startIn + endIn)
      let isProgress = recorderTime + endIn - (endIn - remainingTime)
      dispatch(recordIntro(isProgress));
    }
  }, [recorderTime, remainingTime])

  useEffect(() => {
    if (setIsStopNextQuestion) {
      if (recorderTime === 0 && recording) {
        setIsStopNextQuestion(true)
        let isrecordTiming = recorderTime
        dispatch(recordStopSave(isrecordTiming));
      }
    }
  }, [recorderTime, recording])

  useEffect(() => {
    if ((((endIn - remainingTime) / endIn) * 100) === 100) {
      setRemainingTime(0)
    }
  }, [remainingTime, endIn])

  useEffect(() => {
    if (!recording && recorded && !isPlaying && !isRecordingStop && !isIntoducationScreen) {
      dispatch(recordingStoppedAction(true))
    }
  }, [recorded])

  useEffect(() => {
    if (recording) {
      dispatch(cannotSkipModelAction(false))
    }
  }, [recording])
  console.log(isPlayerStatusRecord, "isPlayerStatusRecord 2");
  return (
    <>
      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box className={classes.recordBg}>
          {/* {recordedAnswer ? */}
          <Typography variant='h6' style={{ textAlign: 'center', marginBottom: '20px' }}> Recorded Answer</Typography>
          {/* : null} */}
          <Typography color="primary" className={classes.currentStatus} style={{ whiteSpace: 'nowrap' }} display="inline">Answer Audio:</Typography>
          {recording && !permissionDenied ? (<Typography variant="body1" className={classes.recordingTitle} style={{ whiteSpace: 'nowrap' }}>{t('recording')}</Typography>) : null}
          {isPlayerStatusRecord ? isPlayerStatusRecord !==true : true &&
            <>
              {!waitingForPlayer && recorderTime !== 0 && step !== 3 &&
                <Typography variant="body1" className={classes.recordingTitle} style={{ whiteSpace: 'nowrap' }}>
                  {question?.question_type === "RepeatSentence" || question?.question_type === "AnswerShortQuestion" ? "" : `Recording in ${recorderTime > 9 ? `${recorderTime}` : `0${recorderTime}`} Seconds`}</Typography>
              }</>}

          {!waitingForPlayer && recorderTime !== 0 && (
            <Box className={classes.mainGapFlex}>
              <Box style={{ width: "100%" }}></Box>
              {recordTimeShow && <Box style={{ display: 'flex', gap: '6px' }}>
              </Box>}
            </Box>
          )}

          {(!recording && recorded && !isPlaying) ? (<Typography className={classes.recordingTitle}> Complete</Typography>) : step === 3 && !recording && !isPlaying ? <Typography className={classes.recordingTitle}> Click Record to Begin </Typography> : null}

          {isPlaying && (<Typography className={classes.recordingTitle}>Playing</Typography>)}

          {waitingForPlayer && (<><Grid item> <Typography variant="subtitle1"> {t('waitingForPlayerToStart')}</Typography></Grid></>)}

          {permissionDenied && (
            <>
              <Grid item>
                <Typography color="primary" display="inline" className={classes.permissionText}  >{t('permissionDenied')}</Typography>
              </Grid>
              <Grid item>
                <Typography color="textPrimary" display="inline" className={classes.permissionText}>{t('activateRecordingPermissionFromSettings')}</Typography>
              </Grid>
            </>
          )}

          {!mock && recorded && !recording && (
            <Grid item className={classes.gridPadding} xs={12} container direction="column" alignItems="center">
              <Player step={step} setIsPlaying={setIsPlaying} setplayerRecord={setplayerRecord} src={audioSrc} t={t} useragent={useragent} playback={playback} recordTimeShow={recordTimeShow} startRecord={startRecord} setRecorded={setRecorded} setRecording={setRecording} setRecorderTimer={setRecorderTimer} setRemainingTime={setRemainingTime} />
            </Grid>
          )}

          {(!recording && (recording ? recorderTime === 0 : "") && remainingTime !== 0 && !recorded && !permissionDenied && !waitingForPlayer) &&
            <>
              <Box style={{ display: 'flex', gap: '12px', }}>
                <CircularProgress size={18} color="primary" />
                <Typography className={classes.recordingTitle}>{t('waitingForMicrophonePermission')}</Typography>
              </Box>
            </>
          }

          {!recording && recorderTime === 0 && remainingTime !== 0 && !recorded && !permissionDenied && waitingForPlayer &&
            <>
              <Box style={{ display: 'flex', gap: '12px' }}>
                <CircularProgress size={18} color="primary" />
                <Typography variant="subtitle1" className={classes.recordingTitle}>{t('waitingForPlayerToStop')}</Typography>
              </Box>
            </>
          }

          {((step === 3 || step === 6) && recorded && !recording) ? null :
            <>
              <Box style={{ display: 'flex', gap: '6px' }}>
                <Box style={{ width: '100%' }}>
                  <LinearProgress variant="determinate" value={((endIn - remainingTime) / endIn) * 100} className={classes.loadingProgress} />
                </Box>
              </Box>
            </>}

          {!recorded &&
            <>
              {playback && <Box className={classes.actionBtn}>
                <Button className={classes.actionBtnStyle} variant="contained" disabled={recording && !permissionDenied} onClick={startRecord}>Record</Button>
                <Button className={classes.actionBtnStyle} variant="contained" disabled={recorderTime !== 0 ? true : (recording && !permissionDenied && !waitingForPlayer)} onClick={startRecord}>Playback</Button>
                <Button className={classes.actionBtnStyle} variant="contained" disabled={!waitingForPlayer && recorderTime !== 0} onClick={stopRecording}>Stop</Button>
              </Box>}
            </>
          }

        </Box>
      </Box>
    </>
  );
};

Recorder.propTypes = {
  t: PropTypes.func.isRequired,
  startIn: PropTypes.number.isRequired,
  endIn: PropTypes.number.isRequired,
  onSaveFinish: PropTypes.func.isRequired,
  onRecordStart: PropTypes.func,
  answer: PropTypes.string,
  startTimer: PropTypes.bool,
  waitingForPlayer: PropTypes.bool,
  useragent: PropTypes.object,
  mock: PropTypes.bool,
  playBeepSound: PropTypes.bool,

  setNextBtnrecordingStop: PropTypes.func,
};

Recorder.defaultProps = {
  answer: null,
  startTimer: true,
  waitingForPlayer: false,
  onRecordStart: () => { },
  useragent: {},
  mock: false,
  playBeepSound: false,
};
export default Recorder;